import { Stack, Chip } from "@mui/material";
import {
  GetLocationListDocument,
  PlanningActivityType,
  RelationAddress,
} from "@cbex/data-access";
import { CustomTextField } from "@cbex/ui/input";
import { CustomAutoCompleteController } from "@cbex/form/autocomplete";
import { CustomTypographyComponent } from "@cbex/ui/text";
import { useTranslations } from "next-intl";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useQuery } from "@apollo/client";
import {
  ExamDetailsDefaultValues,
  NewExamDetailsDefaultValues,
} from "../../utils/ExamDetailsDefaultValues";
interface ExamAddressesDropDownProps {
  name: string;
  controlDisabled?: boolean;
  defaultValue?: string;
  required?: boolean;
  width?: number | string;
  locationDetails?: RelationAddress;
  form: UseFormReturn<ExamDetailsDefaultValues | NewExamDetailsDefaultValues>;
  control?: any;
  inputSelectedActivityTypes?: PlanningActivityType[];
}

export const ExamAddressesAutoComplete = (
  props: ExamAddressesDropDownProps
) => {
  const {
    controlDisabled,
    defaultValue,
    width,
    control,
    required,
    form,
    inputSelectedActivityTypes,
    name,
    locationDetails,
  } = props;
  const t = useTranslations("labels");
  const { formState } = form;
  const [usingLocationDetails, setUsingLocationDetails] = React.useState(false);

  const {
    data: addressTypeData,
    loading: addressTypeDataLoading,
    refetch,
  } = useQuery(GetLocationListDocument, {
    variables: {
      selectedActivityTypes:
        (inputSelectedActivityTypes &&
          //@ts-ignore
          inputSelectedActivityTypes.map((item) => item.activityTypeID)) ||
        [],
      locationType:
        (inputSelectedActivityTypes &&
          inputSelectedActivityTypes.length > 0 &&
          inputSelectedActivityTypes[0].settings &&
          inputSelectedActivityTypes[0].settings.locationType) ||
        "",
    },
  });

  const [addressTypeList, setAddressTypeList] = React.useState<
    RelationAddress[]
  >([]);

  const [addressType, setAddressType] = React.useState<RelationAddress>(null);

  React.useEffect(() => {
    if (
      !addressTypeDataLoading &&
      addressTypeData &&
      addressTypeData.LocationList &&
      addressTypeData.LocationList.length > 0
    ) {
      //@ts-ignore

      const newAddressTypes = addressTypeData.LocationList.map(
        (address: RelationAddress) => ({
          //@ts-ignore
          name: address.name,
          id: address.id,
          city: address.city,
          street: address.street,
          postalCode: address.postalcode,
          number: address.number,
        })
      );
      setAddressTypeList([...newAddressTypes]);
    } else {
      setAddressTypeList([]);
    }
  }, [addressTypeData, addressTypeDataLoading]);

  React.useEffect(() => {
    if (defaultValue && addressTypeList && addressTypeList.length > 0) {
      const foundAddress = addressTypeList.find(
        (address: RelationAddress) => address.id === defaultValue.toString()
      );

      if (foundAddress) {
        setAddressType({
          name: foundAddress.name,
          id: foundAddress.id,
          city: foundAddress.city,
          street: foundAddress.street,
          //@ts-ignore
          postalCode: foundAddress.postalCode,
          number: foundAddress.number,
        });
      } else {
        setUsingLocationDetails(true);
        if (locationDetails) {
          setAddressType(locationDetails);
        }
      }
    } else {
      setAddressType(null);
    }
  }, [addressTypeList, defaultValue, locationDetails]);

  const handleAddressTypeChange = (value: RelationAddress) => {
    setAddressType(value);
  };
  return (
    <Stack id={"OrganizationAddressType"}>
      <CustomAutoCompleteController
        name={name}
        fullObject={true}
        sx={{ width: width ? width : 500 }}
        control={control}
        disableClearable
        //@ts-ignore
        onCustomChange={(val) => handleAddressTypeChange(val)}
        filterSelectedOptions
        disabled={
          addressTypeList.length === 0 ||
          usingLocationDetails ||
          controlDisabled
        }
        // @ts-ignore
        isOptionEqualToValue={(option, val) => option.id === val.id}
        // @ts-ignore
        getOptionLabel={(option) =>
          option.name
            ? option.name + " " + option.city
            : option.street + " " + option.city
        }
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={option.id}
              label={option.name}
            />
          ))
        }
        // @ts-ignore
        renderOption={(propsVal: any, option: any) => (
          <li {...propsVal} key={option.id}>
            <Stack width={"100%"}>
              <CustomTypographyComponent>
                {option.name}
              </CustomTypographyComponent>
              <CustomTypographyComponent>
                {option.street} {option.number} {option.postalCode}{" "}
                {option.city}
              </CustomTypographyComponent>
            </Stack>
          </li>
        )}
        // @ts-ignore
        options={addressTypeList}
        value={addressType || null}
        defaultValue={addressType || null}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            variant="filled"
            size={"small"}
            required={required}
            controldisabled={controlDisabled}
            helperText={
              !!formState.errors["locationId"] &&
              formState.errors["locationId"].message
            }
            error={!!formState.errors["locationId"]}
            label={t("location")}
          />
        )}
      />
    </Stack>
  );
};

export default ExamAddressesAutoComplete;
